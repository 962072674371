import axios from "axios";

// Listeners are moved to vue components

window.fetchReservationData = async () => {
    let formData = new FormData();

    const formEl = document.forms.create_option;

    if(!formEl) {
        return;
    }

    let url = new URL(formEl.dataset.calculateLinesUrl);

    const group = document.querySelector('#create_option_group').value;
    const start = document.querySelector('#create_option_checkInDate').value;
    const end = document.querySelector('#create_option_checkOutDate').value;
    const capacity = document.querySelector('#create_option_amountOfPersons').value;
    const accommodationId = document.querySelector('#create_option_accommodation').value;

    formData.append('group', group);
    formData.append('start', start);
    formData.append('end', end);
    formData.append('capacity', capacity);
    formData.append('accommodation', accommodationId);

    if (group && start && end && capacity) {
        const bookingPricesEl = document.querySelector('#booking_prices');
        const bookingPricesInfoEl = document.querySelector('.js-booking-info-message');
        const bookingPricesLoaderEl = document.querySelector('.booking-prices-loader');

        bookingPricesLoaderEl.classList.remove('d-none');

        const reservationData = axios.post(url.href, formData)
            .then(response => {
                bookingPricesLoaderEl.classList.add('d-none');
                
                if (response.data.success === true) {
                    bookingPricesEl.innerHTML = response.data.data;
                    bookingPricesInfoEl.innerHTML = '';

                    if (response.data.message != undefined) {
                        bookingPricesInfoEl.innerHTML = `<p class="info-feedback">${response.data.message}</p>`;
                    }

                    let changeGroupContextElement = document.querySelector('.change-group-context');
                    if (changeGroupContextElement) {
                        let control = changeGroupContextElement.tomselect;
                        control.setValue(group);
                    }
                }

                if (response.data.success === false) {
                    bookingPricesEl.innerHTML = '';
                    bookingPricesInfoEl.innerHTML = `<p class="invalid-feedback">${response.data.message}</p>`;
                }

                return response.data;
            })
            .catch(error => {
                console.log(error)
            });

        return await reservationData;
    } else if(start && end) {
        let timesUrl = new URL(formEl.dataset.timesUrl);

        const reservationData = axios.post(timesUrl.href, formData)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)
            });

        return await reservationData;
    }
}